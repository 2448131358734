// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap";

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('jquery')
import $ from "jquery";
global.$ = jQuery;

// https://teratail.com/questions/279420#reply-397854
window.bootstrap = require('bootstrap')

import "./style.js";
import "./jquery.raty.js";
//import "./nav.js"
//import "./modal.js";
//import "./popover.js";


require("trix")
require("@rails/actiontext")


